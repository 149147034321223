<script setup lang="ts">
const { getFormattedPrice } = usePrice();
const props = defineProps<{
  value: number | undefined;
  tag?: string;
}>();

const getPrice = computed<string>(() => getFormattedPrice(props.value));
</script>

<template>
  <div v-if="!tag" class="text-m-lg md:text-lg font-medium shared-price">
    {{ getPrice }}
  </div>

  <template v-else>
    <component :is="tag" class="text-m-lg md:text-lg font-medium shared-price">
      {{ getPrice }}
    </component>
  </template>
</template>
